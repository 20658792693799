import useGameStore from "../../../store/useGameStore";
import { Game } from "../../../types/Game";
import fallback_card from "../../../assets/fallback_card_image.webp";
import useSessionStore from "../../../store/useSessionStore";
import { useEffect, useRef, useState } from "react";
import OfflineModal from "./OfflineModal";
import Backdrop from "../../Backdrop";
import {
  EventTypes,
  EventSource,
  MODALS,
  PopupType,
  WebToApp,
} from "../../../constants/Constants";
import useEnvStore from "../../../store/useEnvStore";
import { checkOnline, gameSessionStart } from "../../../services/backend-api";
// import { EventInfo } from "../../../types/window";
// import { callWebToAppFunction } from "../../../utils/androidCallers";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import ErrorModal from "./ErrorModal";
import { EventInfo } from "../../../types/window";
import { callWebToAppFunction } from "../../../utils/androidCallers";

type LandingPageProps = {
  gameId: string;
  onClose: () => void;
};
const LandingPage = ({ gameId, onClose }: LandingPageProps) => {
  const retryHandler = useRef<() => Promise<boolean>>();
  const homePageParameters = useSessionStore((state) => state.homePageParams);
  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [showLoader, setShowLoader] = useState(false);
  //const [isUserOfflineFlag, setIsUserOfflineFlag] = useState<boolean>(false);
  const setGameSessionId = useSessionStore((state) => state.setGameSessionId);
  const games = useGameStore<{ [key: string]: Game[] }>((state) => state.games);
  const navigate = useNavigate();
  const game = Object.values(games)
    .flat()
    .find((game: Game) => game?.id === gameId);
  const closed = useRef(false);
  const currencyData = useSessionStore((state) => state.currencyData);

  const { contentId } = useSessionStore((state) => state.homePageParams);

  const deepLinkGameSession = async () => {
    console.log(`DeepLink: Starting Game session for gameId: ${gameId}`);
    //useEnvStore.getState().setIsOnline(await checkOnline());
    useEnvStore.getState().setIsOnline(await checkOnline());
    if (!useEnvStore.getState().isOnline) {
      console.log("Deeplink: user is offline");
      const modal_viewed_event: EventInfo = {
        eventType: EventTypes.modal_viewed,
        eventProperties: {
          web_timestamp: Date.now(),
          internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
          popup_type: PopupType.OFFLINE,
          source: EventSource.HOME_LOAD,
          content_id: contentId,
        },
      };
      callWebToAppFunction(
        WebToApp.ANALYTICS_LISTENER,
        "",
        "",
        "",
        modal_viewed_event,
        null,
        undefined,
      );
      retryHandler.current = async () => {
        useEnvStore.getState().setIsOnline(await checkOnline());
        if (useEnvStore.getState().isOnline) {
          console.log(
            "DeepLink: I am here with onRetryClickedHandler USER_OFFLINE_REPLAY",
          );
          //await gameOpen(game);
          await deepLinkGameSession();
          return true;
        }
        return false;
      };
      setShowLoader(false);
      setCurrentModal(MODALS.OFFLINE);
      return false;
    } else {
      console.log("DeepLink: user is online");
      //setIsUserOfflineFlag(false);
      setShowLoader(true);
      const success = await gameSessionStart({
        userId: homePageParameters.userId,
        gameId: game!.id,
        bundleId: homePageParameters.bundleId,
        startTime: new Date().toISOString(),
        accountId: homePageParameters.accountId,
        gameConfigId: game!.gameConfigId,
        webviewSessionId: homePageParameters.webviewSessionId,
      })
        .then((res: { data: { gameSessionId: string } }) => {
          // console.log(
          //   "session id created time it took",
          //   performance.now() - time,
          //   "satrt tiume was:",
          //   time
          // );
          //gameSessionId = res?.data?.gameSessionId;
          setGameSessionId(res?.data?.gameSessionId);
          console.log(
            "DeepLink:Game session id created:",
            res?.data?.gameSessionId,
            "navigating to the game",
          );
          setShowLoader(false);
          closed.current = true;
          onClose();
          navigate(`/game/${game?.id}`);
          return true;
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.error("DeepLink:gameSessionStart error api: ", err);
          retryHandler.current = async () => {
            useEnvStore.getState().setIsOnline(await checkOnline());
            if (useEnvStore.getState().isOnline) {
              console.log(
                "DeepLink: onRetryClickedHandler USER_OFFLINE_REPLAY",
              );
              //return await gameOpen(game);
              return await deepLinkGameSession();
            }
            return false;
          };
          console.log("DeepLink:MODAL in catch");
          if (err.response?.status === 408) setCurrentModal(MODALS.OFFLINE);
          else setCurrentModal(MODALS.UNEXPECTED_ERROR);
          setShowLoader(false);
          return false;
        });
      return success;
    }
  };
  //   useEffect(() => {
  //     deepLinkGameSession();
  //   }, [gameId]);
  useEffect(() => {
    const modal_viewed_event: EventInfo = {
      eventType: EventTypes.modal_viewed,
      eventProperties: {
        web_timestamp: Date.now(),
        internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
        popup_type: PopupType.DEEPLINK,
        source: EventSource.HOME_LOAD,
        content_id: contentId,
        referrerflow: PopupType.DEEPLINK,
      },
    };
    callWebToAppFunction(
      WebToApp.ANALYTICS_LISTENER,
      "",
      "",
      "",
      modal_viewed_event,
      null,
      undefined,
    );
    setTimeout(() => {
      if (!closed.current) {
        //firing auto card click amplitude event now
        console.log("Deeplink: sending amplitude event for card click");
        const cardClickEvent: EventInfo = {
          eventType: EventTypes.card_click,
          eventProperties: {
            web_timestamp: Date.now(),
            internetstate: useEnvStore.getState().isOnline
              ? "online"
              : "offline",
            card_name: game?.name,
            card_id: game?.id,
            list_id: game?.id,
            //card_index: index + 1,
            card_type: "auto_launch",
            referrer: PopupType.DEEPLINK,
            content_id: contentId,
          },
        };
        callWebToAppFunction(
          WebToApp.ANALYTICS_LISTENER,
          "",
          "",
          "",
          cardClickEvent,
          null,
          undefined,
        );
        deepLinkGameSession();
      }
    }, 3000);
  }, [gameId]);

  return (
    game && (
      <>
        <div className="fixed z-40 top-0 left-0 right-0 bottom-0 bg-white">
          <img
            src={game?.thumbnailUrl ?? fallback_card}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = fallback_card;
            }}
            alt=""
            className="w-full h-full object-cover blur-sm"
            style={{ width: "100vh", height: "134vh" }}
          />
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-70 text-center text-white">
            <button
              onClick={() => {
                console.log("deeplink: firing event for modal close");
                const modal_viewed_event: EventInfo = {
                  eventType: EventTypes.modal_action,
                  eventProperties: {
                    web_timestamp: Date.now(),
                    internetstate: useEnvStore.getState().isOnline
                      ? "online"
                      : "offline",
                    popup_type: PopupType.DEEPLINK,
                    source: EventSource.HOME_LOAD,
                    action: "Close",
                    content_id: contentId,
                    referrerflow: PopupType.DEEPLINK,
                  },
                };
                callWebToAppFunction(
                  WebToApp.ANALYTICS_LISTENER,
                  "",
                  "",
                  "",
                  modal_viewed_event,
                  null,
                  undefined,
                );
                closed.current = true;
                onClose();
              }}
              className="absolute rounded-full bg-black bg-opacity-50 p-2 text-white"
              aria-label="Close"
              style={{ top: 16, right: 16 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div
              className="absolute text-center text-white"
              style={{
                maxWidth: "50vw",
                top: "50%",
                left: "50%",
                transform: `translate(-50%, -50%)`,
                textShadow: "2px 2px black",
              }}
            >
              <div
                className="block text-center rounded-3xl"
                style={{
                  margin: "0 auto",
                  maxWidth: "50vw",
                  maxHeight: "50vw",
                  overflow: "hidden",
                }}
              >
                <img
                  src={game.thumbnailUrl ?? fallback_card}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = fallback_card;
                  }}
                  alt=""
                  className="object-cover"
                  style={{ width: "50vw" }}
                />
              </div>
              <div className="block font-semibold text-3xl md:text-base m-3">
                {game.name}
              </div>
              <div className="block font-medium text-2xl md:text-base mb-3">
                <span className="text-gray-300">Win Upto </span>
                <img
                  className="inline"
                  src={currencyData.currencyIconUrl}
                />{" "}
                {game.maxEarning}
              </div>
              <div className="block my-4">
                {/*<img className="h-6" src="loader.gif" />*/}
                <div className="progress"></div>
              </div>
            </div>
          </div>
        </div>
        {currentModal == "OFFLINE" && (
          <OfflineModal
            open={currentModal == "OFFLINE"}
            onRetryClickedHandler={async () => {
              setShowLoader(true);
              if (retryHandler.current && (await retryHandler.current())) {
                setCurrentModal(MODALS.NONE);
              }
              setShowLoader(false);
            }}
            exitShow
          ></OfflineModal>
        )}
        {currentModal == "UNEXPECTED_ERROR" && (
          <ErrorModal
            open={currentModal == "UNEXPECTED_ERROR"}
            onRetryClickedHandler={async () => {
              setShowLoader(true);
              if (retryHandler.current && (await retryHandler.current())) {
                setCurrentModal(MODALS.NONE);
              }
              setShowLoader(false);
            }}
            exitShow
          ></ErrorModal>
        )}
        {showLoader && (
          <Backdrop show={true}>
            <div className="spinner"></div>
          </Backdrop>
        )}
      </>
    )
  );
};

export default LandingPage;
