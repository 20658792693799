import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { Game } from "../types/Game";

export type CurrencyData = {
  currencyName: string;
  currencyIconUrl: string;
};
type HomePageParams = {
  bundleId: string;
  thirdPartyId: string;
  accountId: string;
  userId: string;
  webviewSessionId: string;
  sdkVersion: string | null;
  contentId: string | null;
  ipAddress: string | null;
  country: string | null;
};

type GameState =
  | "loading"
  | "menu"
  | "playing"
  | "paused"
  | "autopaused"
  | "over";

export type SessionState = {
  homePageParams: HomePageParams;
  // userId: string;
  currencyData: CurrencyData;
  rewards: number | undefined;
  score: number;
  minScore: number | undefined;
  isCurrentUserRewarded: boolean;
  webViewSessionTimeIn: number;
  setWebViewSessionTimeIn: (webViewSessionTimeIn: number) => void;
  currentGame?: Game;
  gameSessionId: string;
  gameStartTime: number;
  rewardParams?: { [key: string]: any };
  gameState: GameState;
  setCurrentGame: (game?: Game) => void;
  setGameStartTime: (startTime: number) => void;
  // setUserId: (userId: string) => void;
  setCurrencyData: (currencyData: CurrencyData) => void;
  setHomePageParams: (params: Partial<HomePageParams>) => void;
  setScore: (score: number) => void;
  setRewards: (rewards: number | undefined) => void;
  setMinScore: (minScore: number | undefined) => void;
  setIsCurrentUserRewarded: (isRewarded: boolean) => void;
  setGameSessionId: (sessionId: string) => void;
  setRewardParams: (params: { [key: string]: any }) => void;
  setGameState: (gameState: GameState) => void;
};

const useSessionStore = create<SessionState>()(
  persist(
    (set) => ({
      currencyData: {
        currencyName: "",
        currencyIconUrl: "",
      },
      homePageParams: {
        bundleId: "",
        thirdPartyId: "",
        accountId: "",
        userId: "",
        webviewSessionId: "",
        sdkVersion: null,
        contentId: null,
      },
      rewards: 0,
      score: 0,
      minScore: 0,
      isCurrentUserRewarded: false,
      gameSessionId: "",
      gameStartTime: Infinity,
      webViewSessionTimeIn: Infinity,
      gameState: "loading",
      setCurrentGame: (currentGame?: Game) => set({ currentGame }),
      setHomePageParams: (params: Partial<HomePageParams>) =>
        set((state) => ({
          homePageParams: { ...state.homePageParams, ...params },
        })),
      setWebViewSessionTimeIn: (webViewSessionTimeIn) =>
        set({ webViewSessionTimeIn }),
      setGameStartTime: (gameStart) => set({ gameStartTime: gameStart }),
      setGameSessionId: (sessionId) => set({ gameSessionId: sessionId }),
      // setUserId: (userId) => set({ userId }),
      setCurrencyData: (currencyData) => set({ currencyData }),
      setScore: (score) => set({ score }),
      setRewards: (rewards) => set({ rewards }),
      setMinScore: (minScore) => set({ minScore }),
      setIsCurrentUserRewarded: (isRewarded) =>
        set({ isCurrentUserRewarded: isRewarded }),
      setRewardParams: (params) => set({ rewardParams: params }),
      setGameState: (gameState) => set({ gameState }),
    }),
    {
      name: "session-storage",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export default useSessionStore;
