import {
  BackIcon,
  LogoIcon,
  VerticalEllipsisIcon,
} from "../../components/icons";
import { useNavigate } from "react-router-dom";
import { Action, Update, createBrowserHistory } from "history";
let history = createBrowserHistory();

const NavbarOld = () => {
  const navigate = useNavigate();
  return (
    <>
      <nav className="bg-white fixed w-full z-10 border-b border-gray-200">
        <div className="container mx-auto flex items-center justify-between">
          <div className="flex">
            <button
              onClick={() => {
                history.back();
                console.log("back clicked");
              }}
              className="text-black font-semibold flex items-center"
            >
              <div className="p-4">
                <BackIcon />
              </div>
            </button>
            <button className="text-black font-semibold flex items-center">
              <span className="ml-0 font-[500] text-black text-[18px] leading-[21.6px]"></span>
            </button>
            <div className="pt-4">
              <LogoIcon></LogoIcon>
            </div>
          </div>
          <div className="hidden">
            <button
              onClick={() => {
                navigate("/settings");
              }}
              className="focus:outline-none"
            >
              <VerticalEllipsisIcon
                className={"w-[3px] h-[17px]"}
              ></VerticalEllipsisIcon>
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarOld;
