import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { GamePopups, Pages } from "../constants/PageConstants";

type PageState = {
  page: Pages;
  popup: GamePopups;
  documentClicked: boolean;
  didUserVisitViaLandingPage: boolean;
  setPage: (page: Pages) => void;
  setPopup: (popup: GamePopups) => void;
  setDocumentClicked: (documentClicked: boolean) => void;
  setDidUserVisitViaLandingPage: (didUserVisitViaLandingPage: boolean) => void;
};

const usePageStore = create<PageState>()((set, get) => ({
  didUserVisitViaLandingPage: false,
  page: Pages.HOME,
  popup: GamePopups.NONE,
  documentClicked: false,
  setPage: (page) => set({ page }),
  setPopup: (popup) => set({ popup }),
  setDocumentClicked: (documentClicked) => set({ documentClicked }),
  setDidUserVisitViaLandingPage: (didUserVisitViaLandingPage) =>
    set({ didUserVisitViaLandingPage }),
}));

export default usePageStore;
