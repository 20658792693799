import { useState, useRef, useEffect } from "react";
// import PropTypes from "prop-types";
import { callWebToAppFunction } from "../utils/androidCallers";
import { setupAndroidListeners } from "../utils/androidListeners";
/**
 * testfunctioncalledfromandroid: need to remove this no need of it but this is just a test page
 * who cares
 * @returns
 *
 */
const TestPage = () => {
  const [selectedType, setSelectedType] = useState("");
  const [selectedFunction, setSelectedFunction] = useState("");
  const [showTestOutput, setShowTestOutput] = useState("");
  const [mediation, setMediation] = useState("");
  const [adUnit, setAdUnit] = useState("");
  const [placement, setPlacement] = useState("");
  const [failureReason, setFailureReason] = useState("");
  const [mediationCode, setMediationCode] = useState("");
  const [mediationError, setMediationError] = useState("");
  const [url, setUrl] = useState("");
  const [browserType, setBrowserType] = useState("");
  const [bannerAdConfig, setBannerAdConfig] = useState({
    height: "",
    width: "",
    x: "",
    y: "",
    hardwareAcceleration: false,
  });
  const [jsonInput, setJsonInput] = useState("");
  const iframeRef = useRef(null);
  //unused code removing it
  const [showAd, setShowAd] = useState(false);
  const [showContentOnAndroidCall, setShowContentOnAndroidCall] = useState("");

  const appToWebFunctions = [
    "onRDAdDisplayed",
    "onRDAdDisplayFailed",
    "onRDAdDismissed",
    "onRDAdLoadSucceeded",
    "onRDAdLoadFailed",
    "onDistributeRewards",
    "onINSAdDisplayed",
    "onINSAdDisplayFailed",
    "onINSAdDismissed",
    "onINSAdLoadSucceeded",
    "onINSAdLoadFailed",
    "onBNAdExpanded",
    "onBNAdCollapsed",
    "onBNAdClicked",
    "onBNAdLoadSucceeded",
    "onBNAdLoadFailed",
    "invalidBNAdPosition",
    "adSdkNotInitialised",
    "androidBack",
    "userLeftApplication",
    "userResumeApplication",
    //"testfunctioncalledfromandroid",
  ];

  const webToAppFunctions = [
    "closeWebview",
    "analyticsListener",
    "loadRDAd",
    "showRDAd",
    "isRDAdLoaded",
    "loadINSAd",
    "showINSAd",
    "isINSAdLoaded",
    "loadBNAd",
    "destroyBN",
    "callFunctionInAndroid",
    "loadExternalLink",
    "getAppSessionToken",
  ];

  const failFunctions = [
    "onRDAdDisplayFailed",
    "onRDAdLoadFailed",
    "onINSAdDisplayFailed",
    "onINSAdLoadFailed",
    "onBNAdLoadFailed",
  ];

  useEffect(() => {
    // Set up Android listeners
    const cleanup = setupAndroidListeners(
      iframeRef,
      null,
      // setShowContentOnAndroidCall,
      // setShowAd,
      setShowTestOutput,
    );

    return cleanup; // Cleanup listeners when the component unmounts
  }, [iframeRef, setShowContentOnAndroidCall, setShowAd]);

  const handleFunctionCall = () => {
    if (selectedType === "App to Web") {
      if (selectedFunction) {
        // if (selectedFunction === "testfunctioncalledfromandroid")
        //   window[selectedFunction](mediation);
        if (selectedFunction === "adSdkNotInitialised")
          window[selectedFunction](mediation);
        else if (
          selectedFunction === "userLeftApplication" ||
          selectedFunction === "userResumeApplication"
        )
          window[selectedFunction](mediation, adUnit);
        else if (failureReason.includes(selectedFunction)) {
          window[selectedFunction as keyof Window](
            mediation,
            adUnit,
            placement,
            failureReason,
            mediationError,
            mediationCode,
          );
        } else
          window[selectedFunction as keyof Window](
            mediation,
            adUnit,
            placement,
          );
      } else {
        setShowTestOutput("Please select a function to call.");
      }
    } else if (selectedType === "Web to App") {
      //   if (selectedFunction) {
      //     switch (selectedFunction) {
      //       case "closeWebview":
      //         window.Android.closeWebview();
      //         break;
      //       case "analyticsListener":
      //         try {
      //           const eventInfo = JSON.parse(jsonInput);
      //           window.Android.analyticsListener(JSON.stringify(eventInfo));
      //         } catch (error) {
      //           setShowTestOutput("Invalid JSON input");
      //         }
      //         break;
      //       case "loadBNAd":
      //         window.Android.loadBNAd(
      //           mediation,
      //           JSON.stringify(bannerAdConfig),
      //           placement,
      //           adUnit
      //         );
      //         break;
      //       default:
      //         window.Android[selectedFunction](mediation, adUnit, placement);
      //         break;
      //     }
      //   } else {
      //     setShowTestOutput("Please select a function to call.");
      //   }
      if (selectedFunction) {
        callWebToAppFunction(
          selectedFunction,
          mediation,
          adUnit,
          placement,
          jsonInput,
          bannerAdConfig,
          setShowTestOutput,
          url,
          browserType,
        );
      } else {
        setShowTestOutput("Please select a function to call.");
      }
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-xl font-bold mb-4">Android Function Tester</h1>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">
          Select Call Type:
        </label>
        <select
          className="block w-full p-2 border border-gray-300 rounded"
          value={selectedType}
          onChange={(e) => {
            setSelectedType(e.target.value);
            setSelectedFunction(""); // Reset selected function when type changes
            setShowTestOutput("");
          }}
        >
          <option value="">-- Select a call type --</option>
          <option value="Web to App">Web to App</option>
          <option value="App to Web">App to Web</option>
        </select>
      </div>
      {selectedType && (
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">
            Select Function:
          </label>
          <select
            className="block w-full p-2 border border-gray-300 rounded"
            value={selectedFunction}
            onChange={(e) => {
              setSelectedFunction(e.target.value);
              setShowTestOutput("");
            }}
          >
            <option value="">-- Select a function --</option>
            {(selectedType === "App to Web"
              ? appToWebFunctions
              : webToAppFunctions
            ).map((func, index) => (
              <option key={index} value={func}>
                {func}
              </option>
            ))}
          </select>
        </div>
      )}
      {selectedFunction && (
        <>
          {selectedFunction !== "closeWebview" &&
            selectedFunction !== "analyticsListener" &&
            selectedFunction !== "androidBack" &&
            selectedFunction !== "invalidBNAdPosition" &&
            selectedFunction !== "loadExternalLink" && (
              <>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    {selectedFunction === "userLeftApplication" ||
                    selectedFunction === "userResumeApplication"
                      ? "Source:"
                      : selectedFunction === "testfunctioncalledfromandroid"
                        ? "Parameter:"
                        : "Mediation"}
                  </label>
                  <input
                    type="text"
                    className="block w-full p-2 border border-gray-300 rounded"
                    value={mediation}
                    onChange={(e) => setMediation(e.target.value)}
                  />
                </div>
                <div
                  className="mb-4"
                  hidden={
                    selectedFunction === "adSdkNotInitialised" ||
                    selectedFunction === "testfunctioncalledfromandroid"
                  }
                >
                  <label className="block text-sm font-medium mb-2">
                    {selectedFunction === "userLeftApplication" ||
                    selectedFunction === "userResumeApplication"
                      ? "Destination:"
                      : "Ad Unit:"}
                  </label>
                  <input
                    type="text"
                    className="block w-full p-2 border border-gray-300 rounded"
                    value={adUnit}
                    onChange={(e) => setAdUnit(e.target.value)}
                  />
                </div>
                <div
                  className="mb-4"
                  hidden={
                    selectedFunction === "userLeftApplication" ||
                    selectedFunction === "userResumeApplication" ||
                    selectedFunction === "adSdkNotInitialised" ||
                    selectedFunction === "testfunctioncalledfromandroid"
                  }
                >
                  <label className="block text-sm font-medium mb-2">
                    Placement:
                  </label>
                  <input
                    type="text"
                    className="block w-full p-2 border border-gray-300 rounded"
                    value={placement}
                    onChange={(e) => setPlacement(e.target.value)}
                  />
                </div>
              </>
            )}
          {failFunctions.includes(selectedFunction) && (
            <>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  FailureReason:
                </label>
                <input
                  type="text"
                  className="block w-full p-2 border border-gray-300 rounded"
                  value={failureReason}
                  onChange={(e) => setFailureReason(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Mediation Error:
                </label>
                <input
                  type="text"
                  className="block w-full p-2 border border-gray-300 rounded"
                  value={mediationError}
                  onChange={(e) => setMediationError(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Mediation Code:
                </label>
                <input
                  type="text"
                  className="block w-full p-2 border border-gray-300 rounded"
                  value={mediationCode}
                  onChange={(e) => setMediationCode(e.target.value)}
                />
              </div>
            </>
          )}

          {selectedFunction === "loadBNAd" && (
            <>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Banner Ad Config - Height:
                </label>
                <input
                  type="text"
                  className="block w-full p-2 border border-gray-300 rounded"
                  value={bannerAdConfig.height}
                  onChange={(e) =>
                    setBannerAdConfig({
                      ...bannerAdConfig,
                      height: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Banner Ad Config - Width:
                </label>
                <input
                  type="text"
                  className="block w-full p-2 border border-gray-300 rounded"
                  value={bannerAdConfig.width}
                  onChange={(e) =>
                    setBannerAdConfig({
                      ...bannerAdConfig,
                      width: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Banner Ad Config - X:
                </label>
                <input
                  type="text"
                  className="block w-full p-2 border border-gray-300 rounded"
                  value={bannerAdConfig.x}
                  onChange={(e) =>
                    setBannerAdConfig({
                      ...bannerAdConfig,
                      x: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Banner Ad Config - Y:
                </label>
                <input
                  type="text"
                  className="block w-full p-2 border border-gray-300 rounded"
                  value={bannerAdConfig.y}
                  onChange={(e) =>
                    setBannerAdConfig({
                      ...bannerAdConfig,
                      y: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Banner Ad Config - Hardware Acceleration:
                </label>
                <input
                  type="checkbox"
                  className="block p-2 border border-gray-300 rounded"
                  checked={bannerAdConfig.hardwareAcceleration}
                  onChange={(e) =>
                    setBannerAdConfig({
                      ...bannerAdConfig,
                      hardwareAcceleration: e.target.checked,
                    })
                  }
                />
              </div>
            </>
          )}
          {selectedFunction === "analyticsListener" && (
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">
                JSON Input:
              </label>
              <textarea
                className="block w-full p-2 border border-gray-300 rounded h-24"
                value={jsonInput}
                onChange={(e) => setJsonInput(e.target.value)}
                placeholder={JSON.stringify(
                  {
                    eventType: "TestEvent",
                    eventProperties: { key: "value" },
                  },
                  null,
                  2,
                )}
              ></textarea>
            </div>
          )}
          {selectedFunction == "invalidBNAdPosition" && (
            <>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">X:</label>
                <input
                  type="text"
                  className="block w-full p-2 border border-gray-300 rounded"
                  value={mediation}
                  onChange={(e) => setMediation(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Y:</label>
                <input
                  type="text"
                  className="block w-full p-2 border border-gray-300 rounded"
                  value={adUnit}
                  onChange={(e) => setAdUnit(e.target.value)}
                />
              </div>
            </>
          )}
          {selectedFunction === "loadExternalLink" && (
            <>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">URL:</label>
                <input
                  type="text"
                  className="block w-full p-2 border border-gray-300 rounded"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  BrowserType:
                </label>
                <input
                  type="text"
                  className="block w-full p-2 border border-gray-300 rounded"
                  value={browserType}
                  onChange={(e) => setBrowserType(e.target.value)}
                />
              </div>
            </>
          )}
        </>
      )}
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={handleFunctionCall}
      >
        Call Function
      </button>
      <button
        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-4"
        onClick={() => setShowTestOutput("")}
      >
        Clear Output
      </button>
      <div className="mt-4">
        <label className="block text-sm font-medium mb-2">
          Console Output:
        </label>
        <textarea
          className="w-full p-2 border border-gray-300 rounded h-48"
          value={showTestOutput}
          readOnly
        ></textarea>
      </div>
    </div>
  );
};

export default TestPage;
