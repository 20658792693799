import { useNavigate } from "react-router-dom";
import useSessionStore from "../store/useSessionStore";
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Game } from "../types/Game";
import { callWebToAppFunction } from "../utils/androidCallers";
import {
  EventSource,
  EventTypes,
  MODALS,
  PopupType,
  WebToApp,
} from "../constants/Constants";
import { EventInfo } from "../types/window";
import { checkOnline, gameSessionStart } from "../services/backend-api";
import OfflineModal from "./Modal/ModalStyles/OfflineModal";
import Backdrop from "./Backdrop";
import ErrorModal from "./Modal/ModalStyles/ErrorModal";
import * as Sentry from "@sentry/browser";

import useGameStore from "../store/useGameStore";
import useEnvStore from "../store/useEnvStore";

const HeroBanner: React.FC = () => {
  const navigate = useNavigate();
  const setGameSessionId = useSessionStore((state) => state.setGameSessionId);
  const homePageParameters = useSessionStore((state) => state.homePageParams);

  const [showLoader, setShowLoader] = useState(false);

  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const retryHandler = useRef<() => Promise<boolean>>();
  const games = useGameStore<{ [key: string]: Game[] }>((state) => state.games);
  const game = Object.values(games)
    .flat()
    .find((game: Game) => game.name === "Cake Match3");

  useEffect(() => {
    if (currentModal) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "visible";
    }
  }, [currentModal]);

  const handleButtonClick = async () => {
    // console.log("GameCards.tsx:Analytics:Calling button click analytics");

    if (game) {
      const cardClickEvent: EventInfo = {
        eventType: EventTypes.card_click,
        eventProperties: {
          web_timestamp: Date.now(),
          internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
          card_name: game.name,
          card_id: game.id,
          list_id: game.id,
          // card_index: index + 1,
          card_type: "hero_banner",
          referrer: "Homepage",
        },
      };
      callWebToAppFunction(
        WebToApp.ANALYTICS_LISTENER,
        "",
        "",
        "",
        cardClickEvent,
        null,
        undefined,
      );
      // callWebToAppFunction(
      //   WebToApp.LOAD_EXTERNAL_LINK,
      //   "",
      //   "",
      //   "",
      //   {},
      //   "",
      //   null,
      //   "https://swiggy.onelink.me/888564224/acoahlyc",
      //   "com.android.chrome",
      // );
      await gameOpen();
    }
  };
  const gameOpen = async () => {
    useEnvStore.getState().setIsOnline(await checkOnline());
    if (!useEnvStore.getState().isOnline) {
      const modal_viewed_event: EventInfo = {
        eventType: EventTypes.modal_viewed,
        eventProperties: {
          web_timestamp: Date.now(),
          internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
          popup_type: PopupType.OFFLINE,
          source: EventSource.HOME_LOAD,
          game_name: game?.name,
          game: game?.id,
        },
      };
      callWebToAppFunction(
        WebToApp.ANALYTICS_LISTENER,
        "",
        "",
        "",
        modal_viewed_event,
        null,
        undefined,
      );
      retryHandler.current = async () => {
        useEnvStore.getState().setIsOnline(await checkOnline());
        if (useEnvStore.getState().isOnline) {
          console.log(
            "I am here with onRetryClickedHandler USER_OFFLINE_REPLAY",
          );
          await gameOpen();
          return true;
        }
        return false;
      };
      setCurrentModal(MODALS.OFFLINE);
      return false;
    } else {
      console.log("user is online");
      setShowLoader(true);
      const success = gameSessionStart({
        userId: homePageParameters.userId,
        gameId: game!.id,
        bundleId: homePageParameters.bundleId,
        startTime: new Date().toISOString(),
        accountId: homePageParameters.accountId,
        gameConfigId: game!.gameConfigId,
        webviewSessionId: homePageParameters.webviewSessionId,
      })
        .then((res: { data: { gameSessionId: string } }) => {
          // console.log(
          //   "session id created time it took",
          //   performance.now() - time,
          //   "satrt tiume was:",
          //   time
          // );
          //gameSessionId = res?.data?.gameSessionId;
          setGameSessionId(res?.data?.gameSessionId);
          console.log(
            "Game session id created:",
            res?.data?.gameSessionId,
            " navigating to the game",
          );
          setShowLoader(false);
          navigate(`/game/${game?.id}`);
          setCurrentModal(MODALS.NONE);
          return true;
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.error("gameSessionStart error api: ", err);
          const modal_viewed_event: EventInfo = {
            eventType: EventTypes.modal_viewed,
            eventProperties: {
              web_timestamp: Date.now(),
              internetstate: useEnvStore.getState().isOnline
                ? "online"
                : "offline",
              popup_type: PopupType.UNEXPECTED_ERROR,
              source: EventSource.HOME_LOAD,
              game_name: game?.name,
              game_id: game?.id,
            },
          };
          callWebToAppFunction(
            WebToApp.ANALYTICS_LISTENER,
            "",
            "",
            "",
            modal_viewed_event,
            null,
            undefined,
          );
          retryHandler.current = async () => {
            useEnvStore.getState().setIsOnline(await checkOnline());
            if (useEnvStore.getState().isOnline) {
              console.log(
                "I am here with onRetryClickedHandler USER_OFFLINE_REPLAY",
              );
              return await gameOpen();
            }
            return false;
          };
          if (err?.response?.status === 408) setCurrentModal(MODALS.OFFLINE);
          else setCurrentModal(MODALS.UNEXPECTED_ERROR);
          setShowLoader(false);
          return false;
        });
      return success;
    }
  };
  return (
    <>
      {game && (
        <div className="flex flex-col bg-gray-100 p-4 pt-4 pb-0">
          <div className="w-full max-w-4xl mx-auto overflow-hidden">
            <a onClick={handleButtonClick}>
              <img src="banner.webp" className="w-full rounded-3xl" />
            </a>
          </div>
        </div>
      )}
      {currentModal == "OFFLINE" && (
        <OfflineModal
          open={currentModal == "OFFLINE"}
          onRetryClickedHandler={async () => {
            setShowLoader(true);
            if (retryHandler.current && (await retryHandler.current())) {
              setCurrentModal(MODALS.NONE);
            }
            setShowLoader(false);
          }}
          exitShow
        ></OfflineModal>
      )}
      {currentModal == "UNEXPECTED_ERROR" && (
        <ErrorModal
          open={currentModal == "UNEXPECTED_ERROR"}
          onRetryClickedHandler={async () => {
            setShowLoader(true);
            if (retryHandler.current && (await retryHandler.current())) {
              setCurrentModal(MODALS.NONE);
            }
            setShowLoader(false);
          }}
          exitShow
        ></ErrorModal>
      )}
      {showLoader && (
        <Backdrop show={true}>
          <div className="spinner"></div>
        </Backdrop>
      )}
    </>
  );
};
export default HeroBanner;
