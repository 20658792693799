import { create } from "zustand";

type EnvState = {
  env: string;
  sentryDsn: string;
  isOnline: boolean;
  setEnv: (env: string) => void;
  setSentryDsn: (sentryDsn: string) => void;
  setIsOnline: (isOnline: boolean) => void;
};

const useEnvStore = create<EnvState>()((set, get) => ({
  env: "",
  sentryDsn: "",
  isOnline: true,
  setEnv: (env) => set({ env }),
  setSentryDsn: (sentryDsn) => set({ sentryDsn }),
  setIsOnline: (isOnline) => set({ isOnline }),
}));

export default useEnvStore;
