import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { AdUnit } from "../types/AdUnit";

type AdUnitState = {
  adUnits: AdUnit[];
  inHouseAdUnits: AdUnit[] | null;
  getAdUnitByPlacement: (placement_name: string) => AdUnit | undefined;
  getInHouseAdUnitByPlacement: (placement_name: string) => AdUnit | undefined;
  setAdUnits: (adUnits: AdUnit[]) => void;
  setInHouseAdUnits: (adUnits: AdUnit[] | null) => void;
};

const useAdUnitStore = create<AdUnitState>()(
  persist(
    (set, get) => ({
      adUnits: [],
      inHouseAdUnits: null,
      getAdUnitByPlacement: (placement_name) =>
        get().adUnits?.find(
          (adUnit) => adUnit.placementName === placement_name,
        ),
      getInHouseAdUnitByPlacement: (placement_name) =>
        get().inHouseAdUnits?.find(
          (adUnit) => adUnit.placementName === placement_name,
        ),
      setAdUnits: (adUnits) => set({ adUnits }),
      setInHouseAdUnits: (adUnits) => set({ inHouseAdUnits: adUnits }),
    }),
    {
      name: "adUnits-storage",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export default useAdUnitStore;
